$(() => {
    /* HEADER JS START */
    $('.nav-toggle').on('click', function (e) {
        e.stopPropagation();
        $(this).toggleClass('open');
        $(this).closest('.top-menu').toggleClass('open');
        $('.menu-left').toggleClass('collaps');
        $('.left_menu').toggleClass('collaps');
    });

    function initLinkListeners(){
        $('.center-menu a').off('click', checkParentFunction)            
        $('.center-menu a.parent').off('mousedown', mouseDownHandler)
        $(window).off('click', menuCloser)

        if ($(window).width() > 1240){
            $('.center-menu .menu-item>.active').removeClass('active')
        }
        else{
            $('.center-menu a').on('click', checkParentFunction)
            $('.center-menu a.parent').on('mousedown', mouseDownHandler)
            //$(window).on('click', menuCloser)
        }
    }
    function menuCloser(){
        $('.center-menu .active').removeClass('active')
        $('.top-menu .nav-toggle.open').click()
    }

    function mouseDownHandler(){
        var link = $(this).attr('href')
        var start = Date.now()
        
        document.body.onmouseup = function(){
            document.body.onmouseup = null;
            if ((Date.now() - start) > 350 && link.startsWith('/'))
                window.location.href = link;
        }
    }

    function checkParentFunction(e){
        console.log('rok')
        console.log($(this))
        e.stopPropagation();
        if (!$(this).is('.active')) {
            $('.center-menu .menu-item>.active').removeClass('active')
        }
        $(this).toggleClass('active')
        if ($(this).is('.parent')) return false
    }
    $(window).on('resize',initLinkListeners)
    initLinkListeners()

    /* HEADER JS END */
})